<template lang="pug">
v-dialog(v-model="show" max-width="50%")
    v-card(light )
        v-card-title.brand.white--text Installer Preview for {{customer.name}}, {{ customer.state }}
        v-card-text.mt-5
            v-form( v-if="configHasData" v-model="isValid" ref="previewForm" @submit.prevent lazy-validation)
                v-row()
                    v-col()
                        v-select(
                            label="Has Internet"
                            v-model="config.install.hasInternet"
                            :items="yesNo"
                            item-text="name"
                            item-value="value"
                            class="flex-grow-0 flex-shrink-1"
                            ).mr-10
                    v-col()
                        v-select(
                            label="Include Twilio"
                            v-model="config.customer.twilio.enabled"
                            :items="yesNo"
                            item-text="name"
                            item-value="value"
                            :rules="[rules.twilioProps]"
                            class="flex-grow-0 flex-shrink-1"
                            color="brand"
                            ).mr-10
                    v-col
                        v-select(
                            label="Include SparkPost"
                            v-model="config.customer.sparkpost.enabled"
                            :items="yesNo"
                            item-text="name"
                            item-value="value"
                            :rules="[rules.sparkPost]"
                            class="flex-grow-0 flex-shrink-1"
                            outline
                            light
                            color="brand"
                        ).mr-5
                v-row(v-if="config.install.isPublic" dense)
                    v-col(cols="10" sm="6" md="10" class="d-flex")
                        v-text-field(
                            v-model="config.install.domains[0]"
                            label="URL (read-only)"
                            :rules="[rules.urlExists]" readonly
                            class="flex-grow-1 flex-shrink-0"
                            color="brand"
                        )
                v-row()
                    v-col()
                        v-select(
                            label="Primary Server"
                            v-model="config.servers.primary"
                            :items="config.servers.available.filter(s => s.designation ==='primary').map(s => s.name)"
                            class="flex-grow-1 flex-shrink-0"
                            :rules="[rules.required]"
                            :error-messages="form.errors.primary"
                            color="brand"
                            @change="updateServer()"
                            ).mr-4
                    v-col
                        v-select(
                            label="Backup Server"
                            v-model="config.servers.backup"
                            :items="['None', ...config.servers.available.filter(s => s.designation !=='primary').map(s => s.name).sort()]"
                            class="flex-grow-1 flex-shrink-0"
                            color="brand"
                            @change="updateServer()"
                            ).mr-5
                v-row(dense v-if="config.servers.backup !== 'None' && secondaryDomains.filter(s => s.name !== config.servers.backup).length > 0")
                    v-col
                        v-card()
                        v-card-title Secondary Servers
                        v-card-text
                            v-simple-table(dense)
                                template
                                    thead
                                        tr
                                            th Exclude
                                            th Re-order
                                            th Server Name
                                            th Designation
                                            th State
                                    tbody
                                        tr(v-for="(server, index) of secondaryDomains" :key="server.id" v-bind:class="{ excluded: server.exclude}")
                                            th(style="width: 50px;")
                                                v-btn(x-small @click="toggleExclude(server)") {{ server.exclude ? 'Include' : 'Exclude' }}
                                            th(style="width: 110px;")
                                                v-btn(x-small @click.stop="moveDown(index)" v-if="index < secondaryDomains.length - 1")
                                                    v-icon(x-small) arrow_downward
                                                v-btn(x-small v-if="index > 0")
                                                    v-icon(x-small @click.stop="moveUp(index)" ) arrow_upward
                                            td {{server.machineName}}
                                            td {{server.designation}}
                                            td {{server.exclude ? 'Excluded' : 'Included'}}


                v-row()
                    v-col(cols="10" sm="6" md="10" class="d-inline-flex")
                        v-select(
                            label="Encryption Keys",
                            v-model="config.customer.keys.selected",
                            :items="config.customer.keys.available"
                            item-text="type"
                            item-value="id"
                            multiple
                            return-object
                            :rules="[rules.keys]"
                            class="flex-grow-1 flex-shrink-0"
                            :error-messages="form.errors.keys"
                            color="brand"
                            ).mr-5
                        v-select(
                            label="Database User Groups"
                            v-model="config.database.groupsSelected"
                            :items="groups",
                            item-text="name"
                            item-value="name"
                            multiple
                            :rules="[rules.users]"
                            class="flex-grow-1 flex-shrink-0"
                            color="brand"
                        )


            v-container(v-else)
                h3 No configuration data for {{customer.name}}
        v-card-actions
            v-spacer
            v-btn.orange.white--text(
                @click="show=false"
            ) Cancel
            v-btn.brand.white--text(
                :disabled="!isValid"
                @click="createInstaller"
            ) Start

</template>

<script lang="js">

import _ from 'lodash';

export default {
    name: "InstallerPreview",
    props: {
        customer: {
            type: Object,
            default: () => ({}),
            required: true
        },
        visible: Boolean,
        dbUsers: Array
    },
    emits: ["getSingleCustomer", "createInstaller"],
    data () {
        return {
            form: {
                errors: {
                    primary: '',
                    keys: '',
                    users: ''
                }
            },

            secondaryDomains: [],
            originalSecondaryDomains: [],
            config: {},
            isValid: false,
            configHasData: false,
            reasons: [],
            yesNo: [
                {name: 'No', value: false},
                {name: 'Yes', value: true}
            ],
            rules: {
                urlExists: value => {
                    if(!this.config.install.isPublic){
                        return true;
                    }
                    if (value && value.length > 0) {
                        return true;
                    }
                    this.isValid = false;
                    return 'URL is required.';
                },
                required: (value) => {
                    if( Boolean(value) === true ) {
                        this.form.errors.primary = '';
                        return true;
                    }
                    this.isValid = false;
                    this.form.errors.primary = 'Required.';
                    return 'Required.';
                },
                keys: (value) => {
                    // need all available key types
                    const requiredKeyTypes = ['dccsAPI', 'infoscanArchive', 'mongoAuth'];
                    if(value && Array.isArray(value) && value.filter(i => Boolean(i)).length > 0) {
                        const foundKeyTypes = value.map(i => i.type);
                        const allKeysPresent = requiredKeyTypes.every(keyType => foundKeyTypes.includes(keyType));
                        if(allKeysPresent){
                            this.form.errors.keys = '';
                            return true;
                        }
                    }

                    this.isValid = false;
                    this.form.errors.keys = `All ${requiredKeyTypes.length} required keys are not present. See Customers page.`;
                    return 'All required keys are not present.';
                },
                users: (value) => {
                    if(value && Array.isArray(value) && value.filter(i => Boolean(i)).length > 0) {
                        this.form.errors.users = '';
                        return true;
                    }
                    this.isValid = false;
                    this.form.errors.users = 'At least one is required.';
                    return 'At least one is required.';
                },
                twilioProps: value => {
                    let result = true;
                    if(value){
                        const {sid, auth, phones} = this.config.customer.twilio || {};
                        if(sid && auth && phones && phones.length >= 1) {
                            return true;
                        }
                        this.isValid = false;
                        return 'Twilio properties are required (check customer config)';
                    }
                    return result;
                },
                sparkPost: value => {
                    if(value){
                        const {apiKey} = this.config.customer.sparkpost || {};
                        if(apiKey && apiKey.length > 0){
                            return true;
                        }
                        this.isValid = false;
                        return 'SparkPost properties are required (check customer config)';
                    } else {
                        return true;
                    }
                }
            }

        };
    },
    methods: {

        /**
         * removes the http(s) from a url if exists
         * @param {string} domainUrl
         * @returns {string}
         */
        stripProtocol (domainUrl) {
            // missing protocol will yield an invalid url
            if(domainUrl.startsWith('http')){
                const url = new URL(domainUrl);
                return url.host;
            }
            return domainUrl;
        },

        /**
         * Collects the unique user names from the selected groups
         * @param {string[]} groups - group names
         * @returns {string[]} user names
         */
        userGroupsToUsers (groups) {
            const users = this.dbUsers
                .filter(u => groups.includes(u.groupName))
                .map(u => u.username);
            return [...new Set(users)];
        },

        /**
         * @param {object} cfg
         * @param {string} cfg.name
         * @param {string} cfg.state
         * @param {object} cfg.customersConfig
         * @param {object} cfg.customersConfig.branchName
         * @param {object} cfg.customersConfig.storageLocation
         * @returns {{name: string, state: string, branchName: string, storageLocation: string}}}
         */
        convertCustomer (cfg){
            return {
                id: cfg.id,
                name: cfg.name,
                state: cfg.state,
                branchName: cfg.customersConfig.branchName,
                storageLocation: cfg.customersConfig.storageLocation,
            };
        },

        /**
         * @param {{keyContent:string, keyType:string, deployedAt?:number, id:number}[]} keys
         * @returns {available: {content: string, type: string, deployedAt: string, id: number}[], selected:[]}
         */
        convertKeys (keys){
            const available = keys.map(cfg => ({
                content: cfg.keyContent,
                type: cfg.keyType,
                deployedAt: cfg.deployedAt,
                id: cfg.id
            }));
            const selected = [...available];

            return {
                available,
                selected
            };
        },

        /**
         * @param {object} cfg
         * @param {string=} cfg.twilioSID
         * @param {string=} cfg.twilioAuthToken
         * @param {string=} cfg.twilioNumber1
         * @param {string=} cfg.twilioNumber2
         * @returns {{enabled: boolean, sid: string, auth: string, phones: string[]}}
         */
        convertTwilio (cfg){
            const isPropertyValid = prop => prop && prop.length > 0;
            const enabled = ['twilioSID', 'twilioAuthToken', 'twilioNumber1'].every(key => isPropertyValid(cfg[key]));
            return {
                enabled,
                sid: cfg.twilioSID || '',
                auth: `${cfg.twilioAuthToken}`.trim(),
                phones: [cfg.twilioNumber1 || '', cfg.twilioNumber2 || '']
            };
        },

        /**
         * @param {object} cfg
         * @param {object} cfg.sparkPostAPIKey
         * @returns {{enabled: boolean, apiKey: string}}}
         */
        convertSparkPost (cfg){
            const enabled = Boolean( cfg['sparkPostAPIKey'] && cfg['sparkPostAPIKey'].length > 0);
            return {
                enabled,
                apiKey: cfg.sparkPostAPIKey
            };
        },

        /**
         * Creates the base install object
         * @param {object[]} configuration
         * @returns {InstallType}
         */
        convertInstall (configuration){
            const domains = [];
            const isPublic = configuration.isPublic === 1;
            if(isPublic){
                domains.push( this.stripProtocol(configuration.domainUrl));
            }

            // always set hasInternet to false for now
            return {
                hasInternet: configuration.hasInternet === 1,
                isPublic,
                domains
            };
        },

        /**
         * Converts an array of MachineType objects into a new, simpler format.
         * @param {MachineType[]} machines
         * @returns {{type: string, designation:string, name: string, url:string}[]}
         */
        convertServers (machines){
            return machines.map(machine => {
                return {
                    type: machine.type,
                    designation:  machine.designation,
                    name: machine.machineName.trim(),
                    url: this.stripProtocol(machine.url)
                };
            });
        },

        /**
         * Occurs when the previewer is made visible
         * - this is badly named
         * @param {object} data - this.customer
         */
        convertToInstallerConfig (data) {
            if(data) {
                const cloneData = _.cloneDeep(data);
                let keys = [];
                let twilio = {};
                let sparkPost = {};
                let customer ={};
                let servers = {primary: '', };
                let credentials = [];

                let install = {};

                const database = {
                    type: "new",
                    groupsSelected: ['Engineers'],
                    users: []
                };

                if(cloneData.hasOwnProperty('keys')) {
                    keys = this.convertKeys(cloneData.keys);

                }
                if(cloneData.hasOwnProperty('customersConfig')){
                    twilio = this.convertTwilio(cloneData.customersConfig);
                    sparkPost = this.convertSparkPost(cloneData.customersConfig);
                    customer = this.convertCustomer(cloneData);
                    customer.keys = keys;
                    customer.sparkpost = sparkPost;
                    customer.twilio = twilio;

                    install = this.convertInstall( cloneData.customersConfig);
                }

                if(cloneData.hasOwnProperty('customersMachines')){
                    // array of servers objects
                    servers.available = this.convertServers(cloneData.customersMachines);
                    // primary and backup server should be the name string
                    const foundPrimary = servers.available.find(s => s.designation === 'primary');
                    servers.primary = foundPrimary ? foundPrimary.name : "";
                    servers.backup = 'None';
                }

                if(cloneData.hasOwnProperty('customersCredentials')){
                    credentials = cloneData['customersCredentials'].filter(c => c.type === 'machine' );
                }

                this.$set(this, 'config', {
                    customer,
                    install,
                    database,
                    servers,
                    credentials
                });
                this.configHasData = true;
            }
            else {
                this.configHasData = false;
                this.$set(this, 'config', {});
            }
        },

        /**
         * formats the preview configuration into that expected by the installer
         */
        createInstaller () {
            if(this.configHasData) {
                // remove the customer objects used for display
                const properConfig = JSON.parse(JSON.stringify(this.config));

                // twilio
                if(!properConfig.customer.twilio.enabled) {
                    properConfig.customer.twilio.phones = [];
                    properConfig.customer.twilio.sid = '';
                    properConfig.customer.twilio.auth = '';
                }

                // sparkpost
                properConfig.customer.sparkpost = properConfig.customer.sparkpost.enabled ? properConfig.customer.sparkpost.apiKey : '';

                // keys
                const allKeys = _.cloneDeep(properConfig.customer.keys.selected);
                properConfig.customer.keys = [];

                allKeys.forEach(key => {
                    if(key) {
                        properConfig.customer.keys.push({
                            content: key.content,
                            type: key.type,
                            deployedAt: key.deployedAt,
                            id: key.id
                        });
                    }
                });

                // domains
                properConfig.install.domains.push(`${properConfig.servers.primary}:8080`);

                if(properConfig.servers.backup === 'None'){
                    properConfig.servers.backup = '';
                } else {
                    properConfig.install.domains.push(`${properConfig.servers.backup}:8080`);

                    this.secondaryDomains.forEach((m) => {
                        if(!m.exclude) {
                            properConfig.install.domains.push(`${m.machineName}:8080`);
                        }
                    });
                }

                // servers
                let machines = [];
                let counter = 0;
                properConfig.install.domains.forEach(/** @type {string} */ domain => {
                    if( domain.endsWith(':8080')){
                        const name = domain.slice(0, -5);
                        const machine = this.customer['customersMachines'].find(({machineName}) => machineName.trim() === name);
                        if(machine){
                            machine.primary = counter === 0;
                            machine.backup = counter > 0;
                            machine.machineName = machine.machineName.trim();
                            delete machine.createdAt;
                            delete machine.updatedAt;
                            delete machine.osVersion;
                            delete machine.installDate;
                            delete machine.customerId;
                            machines.push(machine);
                            counter += 1;
                        }
                    }
                });
                properConfig.machines = machines;

                // credentials - filter for machines included in domains
                const credentials = [];
                properConfig.credentials.forEach(credential => {
                    const found = machines.find(s => s.id === credential.customersMachineId);
                    if(found) {
                        credential.machineName = found.machineName;
                        delete credential.createdAt;
                        delete credential.updatedAt;
                        credentials.push(credential);
                    }
                });
                properConfig.credentials = credentials;

                delete properConfig.servers.available;

                // database users
                properConfig.database.users = this.userGroupsToUsers(properConfig.database.groupsSelected);
                delete properConfig.database.groupsSelected;

                // After we emit, we will hide the form, but it remains rendered
                this.socketEmit('createInstaller', this.customer.id, properConfig);
                this.configHasData = false;
                this.show = false;
            }
        },

        /**
         * Moves the server up in the list
         * @param {number} idx position of server to move up in array this.secondaryDomains
         */
        moveUp (idx){
            const domains = this.secondaryDomains.map((domain, index) => {
                if(index === idx) {
                    return this.secondaryDomains[index - 1];
                }
                else if(index === idx - 1) {
                    return this.secondaryDomains[index + 1];
                }
                else {
                    return domain;
                }
            });
            this.$set(this, 'secondaryDomains', domains);

        },

        /**
         * Moves the server down in the list
         * @param {number} idx position of server to move down in array this.secondaryDomains
         */
        moveDown (idx){
            const domains = this.secondaryDomains.map((domain, index) => {
                if(index === idx) {
                    return this.secondaryDomains[index + 1];
                }
                else if(index === idx + 1) {
                    return this.secondaryDomains[index - 1];
                }
                else {
                    return domain;
                }
            });
            this.$set(this, 'secondaryDomains', domains);
        },

        /**
         * toggles the exclude property of a machine
         * @param {object} machine
         * @param {boolean} machine.exclude
         */
        toggleExclude (machine){
            machine.exclude = !machine.exclude;
            // find the index of this machine in the array
            const idx = this.secondaryDomains.findIndex(m => m.machineName === machine.machineName);
            // use $set to make sure the property is reactive
            this.$set(this.secondaryDomains, idx, machine);
        },

        /**
         * filter out backup server from secondaryDomains
         * @param {string} backupServerName
         */
        updateServer () {
            // exclude server from secondaryDomains
            this.$set(this, 'secondaryDomains', this.filteredDomains);
        },

    },
    computed: {
        show: {
            get () {
                return this.visible;
            },
            set (visible) {
                if(!visible) {
                    this.$emit('close');
                }
            }
        },

        groups: {
            get () {
                const groups = [];
                const groupMap = new Map(this.dbUsers.map(({groupName, groupId}) => [groupName,groupId]));
                for(const [name,id] of groupMap) {
                    groups.push({name,id});
                }
                return groups;
            }
        },

        filteredDomains: {
            get () {
                const _filteredDomains = this.customer.customersMachines
                    .filter((m,i) => {
                        const machineName = m.machineName.trim();
                        if(machineName === this.config.servers.primary){
                            return false;
                        }
                        if(machineName === this.config.servers.backup){
                            return false;
                        }
                        return m.designation !== 'primary' && m.type === 'server';
                    });
                return _filteredDomains;
            }
        }
    },
    watch: {
        // since life-cycle hooks run before we have a customer, we need to watch for changes
        visible: {
            handler (visible) {
                this.isValid = false;
                if(!visible) {
                    if(this.$refs.previewForm){
                        this.$refs.previewForm.reset();
                        this.$refs.previewForm.resetValidation();
                    }
                }
                if(visible) {
                    this.isValid = false;
                    this.configHasData = false;
                    this.convertToInstallerConfig(this.customer);

                    this.$set(this, 'originalSecondaryDomains', this.filteredDomains);
                    this.$set(this, 'secondaryDomains', _.cloneDeep(this.filteredDomains));

                    this.$nextTick(function () {
                        if(this.$refs.previewForm) {
                            this.$refs.previewForm.resetValidation();
                            this.isValid = this.$refs.previewForm.validate();
                        }
                    });
                }
            }
        }
    },

};

/**
 * @typedef {object} MachineType
 * @property {string} designation
 * @property {string} machineName
 * @property {string} ipAddress
 * @property {string=} url
 */

/**
 * @typedef {object} InstallType
 * @property {string[]} domains - customer url (if isPublic) with server names with 8080 prefixed
 * @property {boolean} isPublic
 * @property {boolean} hasInternet
 */
</script>

<style scoped>
tr.excluded td {
    font-style: italic;
}
</style>
